import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { authReducer } from './auth/authSlice';
import tournamentSlice from './tournamentApi/tournamentSlice';
import usersReducer from './usersApi/usersSlice';
import adminApiSlice from '../services/admin/adminApi';
import apiSlice from '../services/api';
import quickJobsSlice from './quickJobs/quickJobsSlice';
import jobsSlice from './jobsApi/jobsSlice';
import discoverReducer from './discoverApi/discoverSlice';
import companiesReducer from './companiesApi/companiesSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: [
    'auth',
    'jobs',
    'quickJobs',
    'openApi',
    'tournamentApi',
    'discover',
  ],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    users: usersReducer,
    [adminApiSlice.reducerPath]: adminApiSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,

    tournamentApi: tournamentSlice,
    quickJobs: quickJobsSlice,
    companies: companiesReducer,
    jobs: jobsSlice,
    discover: discoverReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/FLUSH',
          'persist/PAUSE',
          'persist/PURGE',
          'persist/REGISTER',
        ],
      },
    }).concat(adminApiSlice.middleware, apiSlice.middleware),
  devTools: true,
});

export const persistor = persistStore(store);
