import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";
import {
  deleteDiscover,
  fetchDiscoverById,
} from "../../../store/discoverApi/discoverThunks";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LazyLoading from "../../../components/LazyLoad";
import EditDiscoverForm from "./EditDiscoverForm";

function DiscoverSingle() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { discover, status } = useSelector((state) => state.discover);
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  // console.log("single discover:", state);
  useEffect(() => {
    dispatch(fetchDiscoverById(id));
  }, [dispatch, id]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDelete = async () => {
    const result = await dispatch(deleteDiscover(discover?._id));
    if (result.payload) {
      toast.success("Discover item deleted successfully!");
      navigate(-1);
    } else {
      toast.error("Failed to delete discover item!");
    }
    setIsModalOpen(false);
  };

  if (status === "loading") {
    return <LazyLoading />;
  }

  return (
    <div className="p-3 sm:p-5 relative">
      <div className="lg:ml-64">
        <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-0 relative">
          <div className="relative">
            <img
              className="w-full h-45 object-cover object-center rounded-t-lg"
              src={discover?.img || "/placeholder.jpg"}
              alt={`${discover?.title} image`}
            />
            <p className="bg-blue-500 text-white text-center px-3 py-1 absolute top-0 left-0">
              {discover?.countryCode}
            </p>
          </div>
          <div className="p-4">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-md text-gray-900 dark:text-gray-300 font-semibold">
                {discover?.title}
              </h3>
              <div className="flex space-x-2">
                <button
                  onClick={() => setIsEditModalOpen(true)}
                  className="px-3 py-1 bg-blue-500 text-white text-sm rounded"
                >
                  Edit
                </button>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="px-3 py-1 bg-red-500 text-white text-sm rounded"
                >
                  Delete
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center mb-2">
              <p className="text-gray-700 dark:text-gray-300 text-sm">
                {discover?.location?.country || "No location specified"}
              </p>
              <div className="flex space-x-1">
                {discover?.tags?.map((tag) => (
                  <span
                    key={tag.keyText}
                    className="text-sm bg-gray-400 px-2 py-1 rounded-sm"
                  >
                    {tag.keyText}
                  </span>
                ))}
              </div>
            </div>
            <div className="text-gray-600 dark:text-gray-400 text-sm mt-2">
              <ReactQuill
                value={discover?.description || "No description available"}
                readOnly={true}
                theme="bubble"
              />
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-20">
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg w-80">
              <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-300 mb-4">
                Are you sure you want to delete this item?
              </h2>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded-lg"
                >
                  No
                </button>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}

        {isEditModalOpen && (
          <EditDiscoverForm
            setShowModal={setIsEditModalOpen}
            existingData={discover}
          />
        )}
      </div>
    </div>
  );
}

export default DiscoverSingle;
