import React from 'react';

function Requests_section() {
  return (
    <div>
      <h3>Requests are here</h3>
    </div>
  );
}

export default Requests_section;
