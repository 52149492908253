import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDiscovers,
  searchDiscovers,
  createDiscover,
  deleteDiscover,
  fetchTags,
  createTag,
  deleteTag,
  fetchDiscoverById,
} from "./discoverThunks";

const initialState = {
  discovers: [],
  discover: null,
  searchResults: [],
  tags: [],
  status: "idle",
  tagsStatus: "idle",
  tagsError: null,
  error: null,
  pagination: {
    currentPage: 1,
    totalPages: 1,
    limit: 10,
    totalDocuments: 0,
  },
};

const discoverSlice = createSlice({
  name: "discover",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.pagination.currentPage = action.payload;
    },
    clearSearchResults(state) {
      state.searchResults = [];
    },
    clearTagSearchResults(state) {
      state.tags = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscovers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDiscovers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discovers = action.payload.discovers.discovers;
        state.pagination.totalDocuments = action.payload.discovers.totalCount;
        state.pagination.totalPages = action.payload.discovers.totalPages;
      })
      .addCase(fetchDiscovers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchDiscoverById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDiscoverById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discover = action.payload;
      })
      .addCase(fetchDiscoverById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(searchDiscovers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchDiscovers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.searchResults = action.payload;
      })
      .addCase(searchDiscovers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createDiscover.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createDiscover.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discovers.unshift(action.payload);
      })
      .addCase(createDiscover.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteDiscover.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDiscover.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discovers = state.discovers.filter(
          (discover) => discover._id !== action.payload
        );
      })
      .addCase(deleteDiscover.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchTags.pending, (state) => {
        state.tagsStatus = "loading";
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.tagsStatus = "succeeded";
        state.tags = action.payload;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.tagsStatus = "failed";
        state.tagsError = action.payload;
      })
      .addCase(createTag.pending, (state) => {
        state.tagsStatus = "loading";
      })
      .addCase(createTag.fulfilled, (state, action) => {
        state.tagsStatus = "succeeded";
        state.tags.unshift(action.payload);
      })
      .addCase(createTag.rejected, (state, action) => {
        state.tagsStatus = "failed";
        state.tagsError = action.payload;
      })
      .addCase(deleteTag.pending, (state) => {
        state.tagsStatus = "loading";
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        state.tagsStatus = "succeeded";
        state.tags = state.tags.filter((tag) => tag._id !== action.payload);
      })
      .addCase(deleteTag.rejected, (state, action) => {
        state.tagsStatus = "failed";
        state.tagsError = action.payload;
      });
  },
});

export const { setCurrentPage, clearSearchResults, clearTagSearchResults } = discoverSlice.actions;
export default discoverSlice.reducer;