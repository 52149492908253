import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createDiscover,
  fetchDiscovers,
  fetchTags,
} from "../../../store/discoverApi/discoverThunks";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LazyLoading from "../../../components/LazyLoad";

const AddDiscoverForm = ({ setShowModal }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    tags,
    status: tagStatus,
    error: tagError,
  } = useSelector((state) => state.discover);
  const discover = useSelector((state) => state.discover);
  // console.log("tags:", discover);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    text: "",
    tags: [],
    countryCode: "",
    location: { country: "", image: null },
    language: "en",
    image: null,
  });

  const countries = [
    { name: "All", code: "all" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "United States", code: "US" },
    { name: "China", code: "CN" },
    { name: "India", code: "IN" },
    { name: "Russia", code: "RU" },
    { name: "Germany", code: "DE" },
    { name: "France", code: "FR" },
    { name: "Japan", code: "JP" },
    { name: "South Korea", code: "KR" },
    { name: "Canada", code: "CA" },
  ];

  const languages = [
    { name: "English", code: "en" },
    { name: "Russian", code: "ru" },
    { name: "Chinese", code: "zh" },
    { name: "Uzbek", code: "uz" },
    { name: "French", code: "fr" },
    { name: "Japanese", code: "jp" },
    { name: "Korean", code: "kr" },
    { name: "German", code: "de" },
    { name: "Spanish", code: "es" },
  ];

  useEffect(() => {
    // console.log("tagStatus:", tagStatus);
    if (tagStatus === "succeeded") {
      dispatch(fetchTags());
    }
  }, [dispatch, tagStatus]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (e) => {
    const selectedCountry = countries.find(
      (country) => country.code === e.target.value
    );
    if (selectedCountry) {
      setFormData({
        ...formData,
        countryCode: selectedCountry.code,
        location: { ...formData.location, country: selectedCountry.name },
      });
    }
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value }); // Save HTML content
  };
  const handleLanguageChange = (e) => {
    setFormData({ ...formData, language: e.target.value, tags: [] });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleLocationImageChange = (e) => {
    setFormData({
      ...formData,
      location: { ...formData.location, image: e.target.files[0] },
    });
  };

  const handleTagChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setFormData((prevState) => {
      const updatedTags = isChecked
        ? [...prevState.tags, value]
        : prevState.tags.filter((tagId) => tagId !== value);
      return { ...prevState, tags: updatedTags };
    });
  };

  // const filteredTags = tags.filter((tag) => tag.language === formData.language);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log("formData.title:", formData.title);
    // console.log("formData.description:", formData.description);
    // console.log("formData.tags.length:", formData.tags.length);
    // console.log("formData.countryCode:", formData.countryCode);
    // console.log("formData.language:", formData.language);
    if (
      !formData.title ||
      !formData.description ||
      formData.tags.length === 0 ||
      !formData.countryCode ||
      !formData.language
    ) {
      toast.error("Please fill out all required fields and select a tag.");
      return;
    }

    if (formData.title.length > 35) {
      toast.error("Title must be 35 characters or less.");
      return;
    }
    const data = new FormData();
    data.append("title", formData.title);
    data.append("description", formData.description);
    data.append("text", formData.text);
    data.append("tags", formData.tags.join(","));
    data.append("countryCode", formData.countryCode);
    data.append("location", JSON.stringify(formData.location));
    data.append("language", formData.language);

    if (formData.image) {
      data.append("image", formData.image);
    }

    if (formData.location.image) {
      data.append("locationImage", formData.location.image);
    }

    dispatch(createDiscover(data)).then((res) => {
      if (res.payload) {
        toast.success("Discover item added successfully!");
        dispatch(fetchDiscovers());
        setShowModal(false);
        setLoading(false);
      } else {
        toast.error("Failed to add discover item!");
        setLoading(false);
      }
    });
  };

  return (
    <div className="fixed inset-0 flex justify-center bg-black bg-opacity-50 z-[1000] overflow-scroll">
      <div className="bg-white p-5 rounded-lg w-full max-w-lg relative border-2 border-gray-300 my-10 overflow-scroll">
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-xl font-bold mb-4">Add New Discover Item</h2>
        {loading ? (
          <LazyLoading />
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Language Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Language <span className="text-red-500">*</span>
              </label>
              <select
                name="language"
                value={formData.language}
                onChange={handleLanguageChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                required
              >
                {languages.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Title */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Title (Max 35 Characters){" "}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                required
              />
            </div>
            {/* Description */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description <span className="text-red-500">*</span>
              </label>
              {/* <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              required
            /> */}
              <ReactQuill
                onChange={handleDescriptionChange}
                style={{ height: "220px" }} // Set height for better usability
              />
            </div>

            {/* Main Image Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mt-14">
                Main Image
              </label>
              <input type="file" name="image" onChange={handleImageChange} />
            </div>

            {/* Location Image Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Location Image
              </label>
              <input
                type="file"
                name="locationImage"
                onChange={handleLocationImageChange}
              />
            </div>
            {/* Discover Item Country Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Discover Item Country <span className="text-red-500">*</span>
              </label>
              <select
                name="countryCode"
                value={formData.countryCode}
                onChange={handleCountryChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                required
              >
                <option value="">Select a country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Tags Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Tags <span className="text-red-500">*</span>
              </label>
              {/* {filteredTags.length === 0 ? (
              <p>No tags available for the selected language.</p>
            ) : ( */}
              <div className="grid grid-cols-2 gap-2">
                {tags.map((tag) => (
                  <label key={tag._id} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      value={tag._id}
                      checked={formData.tags.includes(tag._id)}
                      onChange={handleTagChange}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-sm text-gray-700">{tag.keyText}</span>
                  </label>
                ))}
              </div>
              {/* )} */}
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
              Save
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddDiscoverForm;
