import React, { useState } from "react";

function TableRow({ user, onToggleBlockUser, onChangeRole }) {
  const [selectedRole, setSelectedRole] = useState(user.role);

  const handleRoleChange = (e) => {
    const newRole = e.target.value;
    setSelectedRole(newRole);
    onChangeRole(user._id, newRole);
  };

  return (
    <tr className="border-b">
      <td className="px-4 py-2">{user.fullName}</td>
      <td className="px-4 py-2">{user.gender}</td>
      <td className="px-4 py-2">{user.phoneNumber}</td>
      <td className="px-4 py-2">
        <select
          value={selectedRole}
          onChange={handleRoleChange}
          className="border rounded px-2 py-1"
        >
          <option value="JobSeeker">JobSeeker</option>
          <option value="Employer">Employer</option>
          <option value="Service">Service</option>
          <option value="Admin">Admin</option>
          <option value="SubAdmin">SubAdmin</option>
          <option value="Manager">Manager</option>
        </select>
      </td>
      <td className="px-4 py-2 text-right">
        <button
          onClick={() => onToggleBlockUser(user._id, user.blocked)}
          className={`px-3 py-1 rounded-lg ${
            user.blocked ? "bg-red-500 text-white" : "bg-blue-500 text-white"
          }`}
        >
          {user.blocked ? "Unblock" : "Block"}
        </button>
      </td>
    </tr>
  );
}

export default TableRow;
