import React, { useState } from "react";

function StatusModal({ job, onClose, onConfirm }) {
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleConfirm = () => {
    if (selectedStatus) {
      onConfirm(selectedStatus);
    }
  };

  // console.log(selectedStatus);


  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="text-lg font-semibold mb-4">Ish holatini o'zgartirish</h2>
        <p className="mb-4">Ish tavsifi: {job?.title || job?.jobTitle}</p>
        <div className="mb-4">
          <label className="block text-gray-700">Holatni tanlash:</label>
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="w-full mt-2 p-2 border rounded"
          >
            <option value="" disabled>
              Tanlang
            </option>
            <option value="Approved">Tasdiqlash</option>
            <option value="Rejected">Qaytarish</option>
          </select>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
          >
            Bekor qilish
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 bg-blue-600 text-white rounded"
          >
            Tasdiqlash
          </button>
        </div>
      </div>
    </div>
  );
}

export default StatusModal;
