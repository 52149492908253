import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDiscovers,
  createDiscover,
  deleteDiscover,
  fetchTags,
  createTag,
  deleteTag,
} from "../../../store/discoverApi/discoverThunks";
import LazyLoading from "../../../components/LazyLoad";
import toast from "react-hot-toast";
import AddDiscoverForm from "./AddDiscoverForm";
import DiscoverTags from "./DiscoverTags";
import DiscoverCard from "./DiscoverCard";
import { setCurrentPage } from "../../../store/discoverApi/discoverSlice";

const AdminDiscovers = () => {
  const dispatch = useDispatch();
  const { discovers, status, error, tags, pagination } = useSelector(
    (state) => state.discover
  );
  // console.log("discovers:", discovers);
  // console.log("discovers:", discovers);
  const [showModal, setShowModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  // Filters for Searching
  const [filters, setFilters] = useState({
    query: "",
    language: "",
    tag: "",
  });

  useEffect(() => {
    if (pagination) {
      dispatch(
        fetchDiscovers({
          page: pagination.currentPage,
          limit: pagination.limit,
        })
      );
    }
  }, [dispatch, pagination]);

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleNewDiscover = (discoverData) => {
    dispatch(createDiscover(discoverData)).then(() => {
      setShowModal(false);
      toast.success("Discover created successfully!");
    });
  };

  const handleDelete = (discoverId) => {
    dispatch(deleteDiscover(discoverId)).then(() => {
      toast.success("Discover deleted successfully!");
    });
  };

  const handleAddTag = (tagData) => {
    dispatch(createTag(tagData)).then(() => {
      toast.success("Tag created successfully!");
    });
  };

  const handleDeleteTag = (tagId) => {
    dispatch(deleteTag(tagId)).then(() => {
      toast.success("Tag deleted successfully!");
    });
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      handlePageChange(pagination.currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      handlePageChange(pagination.currentPage - 1);
    }
  };

  return (
    <div className="p-3 sm:p-5">
      <div className="lg:ml-64">
        <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-0">
          <h3 className="text-gray-900 dark:text-gray-800 mb-3 text-2xl font-bold">
            Discovers
          </h3>
          <button
            onClick={() => setShowModal(true)}
            className="bg-blue-600 hover:bg-blue-500 text-white font-medium rounded-lg text-sm px-5 py-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            Add Discover
          </button>
          <button
            onClick={() => setShowTagsModal(true)}
            className="bg-green-600 hover:bg-green-500 text-white font-medium rounded-lg text-sm px-5 py-2.5 focus:ring-4 focus:outline-none focus:ring-green-300"
          >
            Manage Tags
          </button>
        </div>

        {/* Loading & Error Messages */}
        {error && <p className="text-center text-red-500">Error: {error}</p>}
        {status === "loading" ? (
          <LazyLoading />
        ) : (
          <div className="grid mt-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
            {discovers?.length > 0 ? (
              discovers.map((discover) => (
                <DiscoverCard
                  key={discover._id}
                  discover={discover}
                  handleDelete={handleDelete}
                />
              ))
            ) : (
              <p className="text-center text-gray-600">
                {isSearching
                  ? "No search results found."
                  : "No discovers found."}
              </p>
            )}
          </div>
        )}
      </div>

      {/* Pagination */}
      {status === "succeeded" && pagination && (
        <div className="flex justify-center mt-4">
          <button
            onClick={handlePrevPage}
            disabled={pagination.currentPage === 1}
            className="px-4 py-2 mx-1 border rounded bg-white text-blue-500 border-blue-500"
          >
            Previous
          </button>
          {console.log("pagination:", pagination)}
          {Array.from({ length: pagination.totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              disabled={pagination.currentPage === index + 1}
              className={`px-4 py-2 mx-1 border rounded ${
                pagination.currentPage === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-white text-blue-500 border-blue-500"
              }`}
            >
              {index + 1}
            </button>
          ))}

          <button
            onClick={handleNextPage}
            disabled={pagination.currentPage === pagination.totalPages}
            className="px-4 py-2 mx-1 border rounded bg-white text-blue-500 border-blue-500"
          >
            Next
          </button>
        </div>
      )}

      {/* Modal for Adding Discover */}
      {showModal && (
        <AddDiscoverForm
          setShowModal={setShowModal}
          onSubmit={handleNewDiscover}
        />
      )}

      {/* Modal for Managing Tags */}
      {showTagsModal && (
        <DiscoverTags
          tags={tags}
          onAddTag={handleAddTag}
          onDeleteTag={handleDeleteTag}
          setShowModal={setShowTagsModal}
        />
      )}
    </div>
  );
};

export default AdminDiscovers;
