import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDiscover,
  fetchTags,
  fetchDiscoverById,
} from "../../../store/discoverApi/discoverThunks";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditDiscoverForm = ({ setShowModal, existingData }) => {
  const dispatch = useDispatch();
  const { tags } = useSelector((state) => state.discover);
  const countries = [
    { name: "All", code: "all" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "United States", code: "US" },
    { name: "China", code: "CN" },
    { name: "India", code: "IN" },
    { name: "Russia", code: "RU" },
    { name: "Germany", code: "DE" },
    { name: "France", code: "FR" },
    { name: "Japan", code: "JP" },
    { name: "South Korea", code: "KR" },
    { name: "Canada", code: "CA" },
  ];

  const languages = [
    { name: "English", code: "en" },
    { name: "Russian", code: "ru" },
    { name: "Chinese", code: "zh" },
    { name: "Uzbek", code: "uz" },
    { name: "French", code: "fr" },
    { name: "Japanese", code: "jp" },
    { name: "Korean", code: "kr" },
    { name: "German", code: "de" },
    { name: "Spanish", code: "es" },
  ];
  const [formData, setFormData] = useState({
    title: existingData?.title || "",
    description: existingData?.description || "",
    text: existingData?.text || "",
    tags: existingData?.tags.map((tag) => tag._id) || [],
    countryCode: existingData?.countryCode || "",
    location: existingData?.location || { country: "", image: null },
    language: existingData?.language || "en",
    image: null,
  });

  useEffect(() => {
    dispatch(fetchTags());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleLocationImageChange = (e) => {
    setFormData({
      ...formData,
      location: { ...formData.location, image: e.target.files[0] },
    });
  };

  const handleTagChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setFormData((prevState) => {
      const updatedTags = isChecked
        ? [...prevState.tags, value]
        : prevState.tags.filter((tagId) => tagId !== value);
      return { ...prevState, tags: updatedTags };
    });
  };
  const handleCountryChange = (e) => {
    const selectedCountry = countries.find(
      (country) => country.code === e.target.value
    );
    if (selectedCountry) {
      setFormData({
        ...formData,
        countryCode: selectedCountry.code,
        location: { ...formData.location, country: selectedCountry.name },
      });
    }
  };
  const handleLanguageChange = (e) => {
    setFormData({ ...formData, language: e.target.value, tags: [] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !formData.title ||
      !formData.description ||
      formData.tags.length === 0 ||
      !formData.countryCode
    ) {
      toast.error("Please fill out all required fields.");
      return;
    }

    const data = new FormData();
    data.append("title", formData.title);
    data.append("description", formData.description);
    data.append("text", formData.text);
    data.append("tags", formData.tags.join(","));
    data.append("countryCode", formData.countryCode);
    data.append("location", JSON.stringify(formData.location));
    data.append("language", formData.language);

    if (formData.image) {
      data.append("image", formData.image);
    }

    dispatch(updateDiscover({ id: existingData._id, updateData: data })).then(
      (res) => {
        if (res.payload) {
          toast.success("Discover item updated successfully!");
          setShowModal(false);
          dispatch(fetchDiscoverById(existingData._id));
        } else {
          toast.error("Failed to update discover item!");
        }
      }
    );
  };

  return (
    <div className="fixed inset-0 flex justify-center bg-black bg-opacity-50 z-[1000] overflow-scroll">
      <div className="bg-white p-5 rounded-lg w-full max-w-lg relative border-2 border-gray-300 my-10 overflow-scroll">
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          &times;
        </button>

        <h2 className="text-xl font-bold mb-4">Edit Discover Item</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Language <span className="text-red-500">*</span>
            </label>
            <select
              name="language"
              value={formData.language}
              onChange={handleLanguageChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              required
            >
              {languages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </select>
          </div>

          {/* Title */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              required
            />
          </div>

          {/* Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description <span className="text-red-500">*</span>
            </label>
            <ReactQuill
              value={formData.description}
              onChange={handleDescriptionChange}
              style={{ height: "220px" }}
            />
          </div>

          {/* Main Image Upload */}
          <div className="pt-10">
            <label className="block text-sm font-medium text-gray-700">
              Main Image
            </label>
            <input type="file" name="image" onChange={handleImageChange} />
          </div>
          {/* Location Image Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Location Image
            </label>
            <input
              type="file"
              name="locationImage"
              onChange={handleLocationImageChange}
            />
          </div>
          {/* Discover Item Country Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Discover Item Country <span className="text-red-500">*</span>
            </label>
            <select
              name="countryCode"
              value={formData.countryCode}
              onChange={handleCountryChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              required
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          {/* Tags Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Tags <span className="text-red-500">*</span>
            </label>
            <div className="grid grid-cols-2 gap-2">
              {tags.map((tag) => (
                <label key={tag._id} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value={tag._id}
                    checked={formData.tags.includes(tag._id)}
                    onChange={handleTagChange}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-700">{tag.keyText}</span>
                </label>
              ))}
            </div>
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditDiscoverForm;
