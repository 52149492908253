// src/protected/Protected.js

import { Navigate, Outlet } from 'react-router-dom';
import { logout } from '../store/auth/authSlice';
import { signOut } from '../store/auth/authThunks';
import { useEffect } from 'react';
import { fetchCurrentUser } from '../store/usersApi/usersThunks';
import { useDispatch } from 'react-redux';

const useAuth = () => {
  const dispatch = useDispatch();
  let token = null;
  try {
    const storedUserData = localStorage.getItem('userData');
    token = storedUserData ? JSON.parse(storedUserData) : null;
  } catch (error) {
    // console.log('Error parsing token:', error);
    token = null;
  }
  useEffect(() => {
    if (token) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!token) {
      dispatch(logout());
    }
  }, [dispatch, token]);

  return !!token;
};
const Authentication = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export default Authentication;
