import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getSingleCompany,
  updateCompany,
} from "../../../store/companiesApi/companiesThunks";

function EditCompany() {
  const companyId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company, status, error } = useSelector((state) => state.companies);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    location: "",
    size: "",
    type: "",
    phoneNumber: "",
    telegramName: "",
    logo: null,
  });

  useEffect(() => {
    dispatch(getSingleCompany(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (company) {
      setFormData({
        name: company.name,
        description: company.description,
        location: company.location,
        size: company.size,
        type: company.type,
        phoneNumber: company.phoneNumber,
        telegramName: company.telegramName,
        logo: null,
      });
    }
  }, [company]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo") {
      setFormData({ ...formData, logo: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    dispatch(updateCompany({ companyId, formData: formDataToSend })).then(
      () => {
        navigate("/admin/companies");
      }
    );
  };

  return (
    <div className="p-3 sm:p-5">
      <div className="lg:ml-64">
        <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-0">
          <h3 className="text-gray-900 dark:text-gray-800 mb-3 text-2xl font-bold">
            Edit Company
          </h3>
          {status === "loading" && <p>Loading company details...</p>}
          {error && <p className="text-red-500">Error: {error}</p>}
          {status === "succeeded" && (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300">
                  Location
                </label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300">
                  Size
                </label>
                <input
                  type="text"
                  name="size"
                  value={formData.size}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300">
                  Type
                </label>
                <input
                  type="text"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300">
                  Telegram Name
                </label>
                <input
                  type="text"
                  name="telegramName"
                  value={formData.telegramName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300">
                  Logo
                </label>
                <input
                  type="file"
                  name="logo"
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-600 hover:bg-blue-500 text-white font-medium rounded-lg text-sm px-5 py-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300"
              >
                Save Changes
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditCompany;
