// store/authThunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/api';
import toast from 'react-hot-toast';
import { logout } from './authSlice';

export const setTokens = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
  localStorage.setItem("accessToken", data.accessToken);
  localStorage.setItem("refreshToken", data.refreshToken);
};
export const clearTokens = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const login = createAsyncThunk(
  'auth/login',
  async (userData, thunkAPI) => {
    try {
      // console.log('userData in login: ', userData);
      const response = await axios.post('/auth/sign-in', userData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const codeVerify = createAsyncThunk(
  'auth/codeverify',
  async (userData, thunkAPI) => {
    try {
      // console.log('userData in varify: ', userData);
      const response = await axios.post('/auth/sign-in/confirm', userData);
      const { accessToken, refreshToken, role } = response.data.data;
      const userRole = role?.charAt(0).toLowerCase() + role?.slice(1) || null;

      if (userRole === 'admin') {
        setTokens({ accessToken, refreshToken });
        return { ...response.data.data, logged: true };
      } else if (userRole === null || userRole !== 'admin') {
        return null;
      }
    } catch (error) {
      toast.error("Tasdiqlash kodini to'g'ri kiriting!");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const signOut = createAsyncThunk(
  'auth/signOut',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/sign-out', {
        mobileToken: 'fcmToken',
      });
      clearTokens();
      dispatch(logout());
      window.location.href = "/login";

      toast.success('Tizimdan chiqildi!');

      return response.data;
    } catch (error) {
      toast.error('Tizimdan chiqishda xatolik!');
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  'auth/deleteAccount',
  async () => {
    try {
      const response = await axios.delete('/auth/deleteAccount');
      clearTokens();
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
