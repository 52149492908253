// src/api/api.js
import axios from 'axios';
import { clearTokens, setTokens } from "../store/auth/authThunks";
export const BASE_URL_DOMAIN = process.env.REACT_APP_API_URL;
const API_BASE_URL = `${BASE_URL_DOMAIN}/api/v1`;
console.log("API_BASE_URL:", API_BASE_URL);
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: false,
});

export const BASE_URL = API_BASE_URL;
export const BASE_URL_ADMIN = `${API_BASE_URL}/admin`;

const refreshTokenEndpoint = '/auth/renewAccessToken';
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const refreshToken = async () => {
  const storedRefreshToken = localStorage.getItem("refreshToken");
  if (!storedRefreshToken) {
    console.error('No refresh token found in local storage');
    return null;
  }

  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    });
  }

  isRefreshing = true;
  try {
    console.log("Refreshing token...");
    const response = await api.post(refreshTokenEndpoint, { refreshToken: storedRefreshToken });

    if (response.status !== 208) {
      console.error(`Failed to refresh token: status code is ${response.status}`);
      return null;
    }

    const { accessToken, refreshToken: newRefreshToken } = response.data?.data || {};
    if (!accessToken || !newRefreshToken) {
      console.error("Failed to refresh token: missing token");
      return null;
    }

    setTokens({ accessToken, refreshToken: newRefreshToken });
    processQueue(null, accessToken);
    return accessToken;
  } catch (error) {
    console.error('Failed to refresh token:', error.message);
    processQueue(error, null);
    clearTokens();
    return null;
  } finally {
    isRefreshing = false;
  }
};

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 451) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          originalRequest.headers["Authorization"] = `${newAccessToken}`;
          return api(originalRequest);
        }
      }
      clearTokens();
    }

    return Promise.reject(error);
  }
);

const initializeAuth = async () => {
  await refreshToken();
};

initializeAuth();

export default api;
