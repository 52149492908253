import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";

function CompaniesCard({ company, handleDelete, handleChangeStatus }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const confirmDelete = () => {
    handleDelete(company?._id);
    setIsModalOpen(false);
  };

  const changeStatus = (status) => {
    handleChangeStatus(company?._id, status);
  };

  return (
    <div
      key={company?._id}
      className="bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 relative"
    >
      <Link to={`/admin/companies/${company?._id}`}>
        <div className="relative">
          <img
            className="w-full h-45 object-cover object-center rounded-t-lg"
            src={company?.logo[0] || "/placeholder.jpg"}
            alt={`${company?.name} logo`}
          />
          <p className="bg-blue-500 text-white text-center px-3 py-1 absolute top-0 left-0">
            {company?.name}
          </p>
        </div>
      </Link>
      <div className="p-4">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          {company?.name}
        </h3>
        <p className="text-gray-600 dark:text-gray-400">
          {company?.description}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          Location: {company?.location}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          Size: {company?.size}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          Type: {company?.type}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          Phone: {company?.phoneNumber}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          Telegram: {company?.telegramName}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          Status: {company?.status}
        </p>
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => setIsModalOpen(true)}
            className="text-red-500 hover:text-red-700"
          >
            Delete
          </button>
          <button
            onClick={toggleMenu}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaEllipsisV />
          </button>
        </div>
        {isMenuOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg z-10">
            <Link
              to={`/admin/companies/edit/${company?._id}`}
              className="block px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              Edit
            </Link>
            <button
              onClick={() => changeStatus("approved")}
              className="block w-full text-left px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              Approve
            </button>
            <button
              onClick={() => changeStatus("rejected")}
              className="block w-full text-left px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              Reject
            </button>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
              Confirm Delete
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Are you sure you want to delete this company?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="text-red-500 hover:text-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompaniesCard;
