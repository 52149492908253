import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, refreshToken } from "../api/api";
import { logout, updateAccessToken } from "../store/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 451) {
    const newAccessToken = await refreshToken();
    if (newAccessToken) {
      api.dispatch(updateAccessToken(newAccessToken));
      result = await baseQuery(args, api, extraOptions);
    } else {
      localStorage.clear();
      api.dispatch(logout());
      window.location.href = "/login";
    }
  }

  return result;
};

export default baseQueryWithReauth;
