import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL_ADMIN } from "../../api/api";
import { logout, updateAccessToken } from "../../store/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL_ADMIN,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauthAdmin = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 451) { // Updated status code to match refresh logic
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      api.dispatch(logout());
      window.location.href = "/login";
      return result;
    }

    // Attempt to refresh the token
    const refreshResult = await baseQuery(
      {
        url: "/auth/renewAccessToken",
        method: "POST",
        body: { refreshToken },
      },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      const { accessToken, refreshToken: newRefreshToken } = refreshResult.data.data;

      if (!accessToken || !newRefreshToken) {
        console.error("Failed to refresh token: missing token");
        localStorage.clear();
        api.dispatch(logout());
        window.location.href = "/login";
        return result;
      }

      // Store the new tokens
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", newRefreshToken);

      // Retry the original query with the new token
      api.dispatch(updateAccessToken(accessToken));
      result = await baseQuery(args, api, extraOptions);
    } else {
      console.error("Failed to refresh token, logging out");
      localStorage.clear();
      api.dispatch(logout());
      window.location.href = "/login";
    }
  }

  return result;
};

export default baseQueryWithReauthAdmin;
