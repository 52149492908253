/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Label, Modal, TextInput, Select } from 'flowbite-react';
import { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { registerUserByAdmin } from '../../../../store/usersApi/usersThunks'; // Adjust the import path as needed
import toast from 'react-hot-toast';

function AddUserModal() {
  const [isOpen, setOpen] = useState(false);

  // Form fields for user creation
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('JobSeeker'); // Set a default from the given enum

  const dispatch = useDispatch();
  const status = useSelector((state) => state.users.status);
  const error = useSelector((state) => state.users.error);

  const handleSubmit = () => {
    // Dispatch the thunk to register a new user by admin.
    dispatch(registerUserByAdmin({ phoneNumber, role }))
      .unwrap()
      .then(() => {
        toast.success('User registered successfully by admin.');
        setOpen(false);
        setPhoneNumber('');
        setRole('JobSeeker');
      })
      .catch((err) => {
        // Show an error toast if the registration fails
        toast.error(`Failed to register user by admin: ${err}`);
      });
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        <div className="flex items-center gap-x-3">
          <HiPlus className="text-xl" />
          Add user
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Add new user</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <Label htmlFor="phone">Phone number (required)</Label>
              <div className="mt-1">
                <TextInput
                  id="phone"
                  name="phone"
                  placeholder="e.g., 901234567"
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>

            <div>
              <Label htmlFor="role">Role (required)</Label>
              <div className="mt-1">
                <Select
                  id="role"
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="JobSeeker">JobSeeker</option>
                  <option value="Employer">Employer</option>
                  <option value="Service">Service</option>
                  <option value="SubAdmin">SubAdmin</option>
                  <option value="Manager">Manager</option>
                </Select>
              </div>
            </div>
          </div>
          {/* Display loading or error states */}
          {status === 'loading' && <p>Registering user...</p>}
          {status === 'failed' && (
            <p className="text-red-500">Error: {error}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={handleSubmit}>
            Add user
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddUserModal;
