import React from "react";

function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  totalDocuments,
  limit,
}) {
  const startIndex = (currentPage - 1) * limit;

  return (
    <nav className="flex justify-between items-center mt-4">
      <span className="text-sm text-gray-500">
        Showing {startIndex + 1}-{Math.min(startIndex + limit, totalDocuments)}{" "}
        of {totalDocuments}
      </span>
      <div className="flex space-x-2">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 border rounded-lg disabled:opacity-50"
        >
          Previous
        </button>
        {[...Array(totalPages).keys()].map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page + 1)}
            className={`px-3 py-1 border rounded-lg ${
              currentPage === page + 1 ? "bg-blue-500 text-white" : ""
            }`}
          >
            {page + 1}
          </button>
        ))}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 border rounded-lg disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </nav>
  );
}

export default Pagination;
