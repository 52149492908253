import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const createCompany = createAsyncThunk(
  "companies/createCompany",
  async (companyData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/companies", companyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approveCompany = createAsyncThunk(
  "companies/approveCompany",
  async ({ companyId, status }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/companies/${companyId}/approve`, { status });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getApprovedCompanies = createAsyncThunk(
  "companies/getApprovedCompanies",
  async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get("/companies/approved", {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPendingCompanies = createAsyncThunk(
  "companies/getPendingCompanies",
  async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get("/companies/pending", {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRejectedCompanies = createAsyncThunk(
  "companies/getRejectedCompanies",
  async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get("/companies/rejected", {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompaniesStatus = createAsyncThunk(
  "companies/getCompaniesStatus",
  async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get("/companies/status", {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "companies/deleteCompany",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/companies/${companyId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllCompany = createAsyncThunk(
  "companies/getAllCompany",
  async (filters, { rejectWithValue }) => {
    try {
      const response = await axios.get("/companies", {
        params: filters,
      });
      console.log("all companies:", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSingleCompany = createAsyncThunk(
  "companies/getSingleCompany",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/companies/${companyId}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "companies/updateCompany",
  async ({ companyId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/companies/${companyId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCompanyMinorChange = createAsyncThunk(
  "companies/updateCompanyMinorChange",
  async ({ companyId, companyData }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/companies/${companyId}/minorChange`, companyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
