import React from "react";
import Admin_users from "./Admin_users";

function Admin_managers() {
  return (
    <div className="lg:ml-64">
      <div className="rounded-lg  dark:border-gray-700 lg:mt-16 mt-0">
        <Admin_users />
      </div>
    </div>
  );
}

export default Admin_managers;
