import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";

function DiscoverCard({ discover, handleDelete }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const confirmDelete = () => {
    handleDelete(discover?._id);
    setIsModalOpen(false);
  };

  return (
    <div
      key={discover?._id}
      className="bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 relative"
    >
      <Link to={`/admin/discovers/${discover?._id}`}>
        <div className="relative">
          <img
            className="w-full h-45 object-cover object-center rounded-t-lg"
            src={discover?.img || "/placeholder.jpg"}
            alt={`${discover?.title} image`}
          />
          <p className="bg-blue-500 text-white text-center px-3 py-1 absolute top-0 left-0">
            {discover?.countryCode}
          </p>
        </div>
        <div className="p-4">
          <h3 className="text-md text-gray-900 dark:text-gray-300 font-semibold mb-2">
            {discover?.title}
          </h3>
          <p className="text-gray-700 dark:text-gray-300 text-sm">
            {discover?.location?.country || "No location specified"}
          </p>

          <p className=" dark:text-gray-300  bg-blue-500 text-white w-6 text-lg text-center">
            {discover?.language || "No location specified"}
          </p>
        </div>
      </Link>

      <div className="absolute top-0 right-1 bg-blue-500 text-white text-center  py-1 ">
        <button
          onClick={toggleMenu}
          className="text-gray-600 dark:text-gray-300 focus:outline-none"
        >
          <FaEllipsisV color="white" />
        </button>
        {isMenuOpen && (
          <div className="absolute right-0 mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded shadow-lg py-1 z-10">
            <button
              onClick={() => setIsModalOpen(true)}
              className="block px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-left"
            >
              Delete
            </button>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-20">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg w-80">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-300 mb-4">
              Are you sure you want to delete this item?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded-lg"
              >
                No
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DiscoverCard;
