import React from 'react';

import ChartTwo from '../../components/charts/ChartTwo';
import {
  useGetCompanyQuery,
  useGetEmployersQuery,
  useGetJobSeekersQuery,
  useGetJobsQuery,
  useGetQuickJobsQuery,
  useGetUsersQuery,
} from '../../services';
import { MdOutlineHomeWork, MdWorkHistory } from 'react-icons/md';
import { BsPersonWorkspace } from 'react-icons/bs';
import { GrWorkshop } from 'react-icons/gr';
import {
  FiUsers,
  FiBriefcase,
  FiGlobe,
  FiFileText,
  FiClock,
} from 'react-icons/fi';
function Dashboard() {
  const { data: users } = useGetUsersQuery();
  const { data: jobseeker } = useGetJobSeekersQuery();
  const { data: employer } = useGetEmployersQuery();
  const { data: jobs } = useGetJobsQuery();
  const { data: quickjobs } = useGetQuickJobsQuery();
  const { data: company } = useGetCompanyQuery();

  return (
    <div className="p-4 bg-white dark:bg-slate-700 lg:ml-64">
      <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-16">
        <div className="grid md:grid-cols-3 gap-4 mb-4">
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50  dark:bg-gray-800">
            <div className="flex items-center gap-4">
              <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <FiUsers className="text-primary dark:text-white text-2xl" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-title-md font-bold text-black dark:text-white">
                  {users?.totalCount || 0} ta
                </h4>
                <span className="text-sm font-medium text-black dark:text-white">
                  Foydalanuvchilar
                </span>
              </div>
            </div>
          </div>
          {/* jobseeker */}
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50  dark:bg-gray-800">
            <div className="flex items-center gap-4">
              <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <BsPersonWorkspace className="text-primary dark:text-white text-2xl" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-title-md font-bold text-black dark:text-white">
                  {jobseeker?.totalCount || 0} ta
                </h4>
                <span className="text-sm font-medium text-black dark:text-white">
                  Ish izlovchilar
                </span>
              </div>
            </div>
          </div>
          {/* employer */}
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50  dark:bg-gray-800">
            <div className="flex items-center gap-4">
              <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <GrWorkshop className="text-primary dark:text-white text-2xl" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-title-md font-bold text-black dark:text-white">
                  {employer?.totalCount || 0} ta
                </h4>
                <span className="text-sm font-medium text-black dark:text-white">
                  Ish beruvchilar
                </span>
              </div>
            </div>
          </div>

          {/* jobs */}
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50  dark:bg-gray-800">
            <div className="flex items-center gap-4">
              <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <FiBriefcase className="text-primary dark:text-white text-2xl" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-title-md font-bold text-black dark:text-white">
                  {jobs?.totalCount || 0} ta
                </h4>
                <span className="text-sm font-medium text-black dark:text-white">
                  Ishlar
                </span>
              </div>
            </div>
          </div>

          {/* companies */}
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50  dark:bg-gray-800">
            <div className="flex items-center gap-4">
              <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <MdOutlineHomeWork className="text-primary dark:text-white text-2xl" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-title-md font-bold text-black dark:text-white">
                  {company?.totalCount || 0} ta
                </h4>
                <span className="text-sm font-medium text-black dark:text-white">
                  Korxonalar
                </span>
              </div>
            </div>
          </div>

          {/* quickjobs */}
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50  dark:bg-gray-800">
            <div className="flex items-center gap-4">
              <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <MdWorkHistory className="text-primary dark:text-white text-2xl" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-title-md font-bold text-black dark:text-white">
                  {quickjobs?.totalCount || 0} ta
                </h4>
                <span className="text-sm font-medium text-black dark:text-white">
                  Tezkor ishlar
                </span>
              </div>
            </div>
          </div>
        </div>
        <ChartTwo />
      </div>
    </div>
  );
}

export default Dashboard;
