// Loading.js
import React from 'react';
import './LazyLoad.css';

const LazyLoading = () => {
  return (
    <div className="parent-container">
      <div className="loader"></div>
    </div>
  );
};

export default LazyLoading;
