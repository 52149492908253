import { createSlice } from "@reduxjs/toolkit";
import {
  createCompany,
  approveCompany,
  getApprovedCompanies,
  getPendingCompanies,
  getRejectedCompanies,
  getCompaniesStatus,
  deleteCompany,
  getAllCompany,
  getSingleCompany,
  updateCompany,
  updateCompanyMinorChange,
} from "./companiesThunks";

const initialState = {
  companies: [],
  company: null,
  status: "idle",
  error: null,
  pagination: {
    currentPage: 1,
    totalPages: 1,
    limit: 10,
    totalDocuments: 0,
  },
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.pagination.currentPage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    resetSearchCompany(state) {
      state.filters = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies.push(action.payload);
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(approveCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approveCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id, status } = action.payload;
        const existingCompany = state.companies.find((company) => company.id === id);
        if (existingCompany) {
          existingCompany.status = status;
        }
      })
      .addCase(approveCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getApprovedCompanies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getApprovedCompanies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies = action.payload.companies;
        state.pagination = action.payload.pagination;
      })
      .addCase(getApprovedCompanies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getPendingCompanies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPendingCompanies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies = action.payload.companies;
        state.pagination = action.payload.pagination;
      })
      .addCase(getPendingCompanies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getRejectedCompanies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRejectedCompanies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies = action.payload.companies;
        state.pagination = action.payload.pagination;
      })
      .addCase(getRejectedCompanies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getCompaniesStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCompaniesStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies = action.payload.companies;
        state.pagination = action.payload.pagination;
      })
      .addCase(getCompaniesStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies = state.companies.filter(
          (company) => company.id !== action.payload.id
        );
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAllCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(getAllCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getSingleCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSingleCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload;
      })
      .addCase(getSingleCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id, ...updatedData } = action.payload;
        const existingCompany = state.companies.find((company) => company.id === id);
        if (existingCompany) {
          Object.assign(existingCompany, updatedData);
        }
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateCompanyMinorChange.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCompanyMinorChange.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id, ...updatedData } = action.payload;
        const existingCompany = state.companies.find((company) => company.id === id);
        if (existingCompany) {
          Object.assign(existingCompany, updatedData);
        }
      })
      .addCase(updateCompanyMinorChange.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setCurrentPage, setFilters, resetSearchCompany } = companiesSlice.actions;
export default companiesSlice.reducer;