import adminApiSlice from './adminApi';

export const userApiSlice = adminApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `getUsersForAdmin?page=${page}&limit=${limit}`,
      providesTags: [{ type: 'User', id: 'LIST' }],
    }),
    getAdmins: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `getAdmins?page=${page}&limit=${limit}`,
      providesTags: [{ type: 'Admin', id: 'LIST' }],
    }),
    blockUser: builder.mutation({
      query: (userId) => ({
        url: `blockUser/${userId}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    unblockUser: builder.mutation({
      query: (userId) => ({
        url: `unBlockUser/${userId}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    changeRole: builder.mutation({
      query: ({ userId, role }) => ({
        url: `changeRole/${userId}`,
        method: 'POST',
        body: { role },
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    getJobSeekers: builder.query({
      query: () => 'getJobSeekersForAdmin',
      providesTags: [{ type: 'JobSeeker', id: 'LIST' }],
    }),
    getEmployers: builder.query({
      query: () => 'getEmployersForAdmin',
      providesTags: [{ type: 'Employer', id: 'LIST' }],
    }),
    getJobs: builder.query({
      query: () => 'getJobsForAdmin',
      providesTags: [{ type: 'Job', id: 'LIST' }],
    }),
    getQuickJobs: builder.query({
      query: () => 'getQuickjobsForAdmin',
      providesTags: [{ type: 'QuickJob', id: 'LIST' }],
    }),
    getCompanies: builder.query({
      query: () => 'getOfficesForAdmin',
      providesTags: [{ type: 'Company', id: 'LIST' }],
    }),
    getCompany: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `getOfficesForAdmin/?page=${page}&limit=${limit}`,
      providesTags: [{ type: 'Office', id: 'LIST' }],
    }),
    createCompany: builder.mutation({
      query: (newCompany) => ({
        url: '/offices',
        method: 'POST',
        body: newCompany,
      }),
      invalidatesTags: [{ type: 'Office', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetAdminsQuery, // Added query hook for getAdmins
  useBlockUserMutation,
  useUnblockUserMutation,
  useChangeRoleMutation,
  useGetJobSeekersQuery,
  useGetEmployersQuery,
  useGetJobsQuery,
  useGetQuickJobsQuery,
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useCreateCompanyMutation,
} = userApiSlice;
