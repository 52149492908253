import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAuth from '../../../hooks/useAuth';
import InputMask from 'react-input-mask';
import bcg from '../../../assets/img/bcg.jpg';
import toast from 'react-hot-toast';
import { countries } from '../../../assets/global/countryCodes';

const SignIn = () => {
  // Set Uzbekistan as the default country
  const uzbekistan = countries.find((country) => country.name === 'Uzbekistan');
  const [selectedCountry, setSelectedCountry] = useState(uzbekistan || countries[0]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [seconds, setSeconds] = useState(60);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const { isLoading } = useSelector((state) => state.auth);
  const { userLogin, userCodeVerify } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isCodeSent && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    } else if (seconds === 0 && isCodeSent) {
      setIsCodeSent(false);
    }
    return () => clearInterval(timer);
  }, [isCodeSent, seconds]);

  const handleChange = (event) => {
    const country = countries.find(
      (number) => number.code === event.target.value
    );
    setSelectedCountry(country);
    setPhoneNumber('');
  };

  const handleSendCode = async (e) => {
    e.preventDefault();

    const fullPhoneNumber = `${selectedCountry.code}${phoneNumber.replace(
      /\D/g,
      ''
    )}`;
    // console.log('Sending code for phone number:', fullPhoneNumber);
    if (fullPhoneNumber === '') {
      toast.error('Telefon raqam kiriting');
      return;
    }

    try {
      const response = await userLogin({ phoneNumber: fullPhoneNumber });
      // console.log('Response from userLogin:', response);

      if (response) {
        toast.success('Kod yuborildi');
        setIsCodeSent(true);
        setSeconds(60);
      }
    } catch (error) {
      // console.log('Error sending code:', error);
      if (error.msg === 'User not found') {
        toast.error('Foydalanuvchi topilmadi!');
      } else {
        toast.error("Xatolik yuz berdi, qayta urinib ko'ring");
      }
    }
  };

  const handleCodeSubmit = (e) => {
    e.preventDefault();

    const rawPhoneNumber = phoneNumber.replace(/\D/g, '').slice(0, 12);
    // console.log('Confirming code for phone number:', rawPhoneNumber);
    userCodeVerify({
      phoneNumber: rawPhoneNumber,
      confirmationCode: confirmationCode,
    })
      .then((res) => {
        const formattedRole =
          res?.role?.charAt(0).toLowerCase() + res?.role?.slice(1) || null;
        if (formattedRole === 'admin') {
          navigate('/admin', { replace: true });
        } else {
          toast.error("Sizga kirishga ruxsat yo'q");
          navigate('/login', { replace: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      style={{ backgroundImage: `url(${bcg})` }}
      className="overflow-x-hidden h-screen bg-no-repeat bg-cover bg-[center_center]"
    >
      <div className="absolute z-1 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-full max-w-[500px]">
        <h3 className="text-sky-950 text-center mb-12 text-3xl not-italic font-normal">
          Topish AI
        </h3>
        <div className="overflow-hidden bg-[#fff] p-7 my-[0] rounded-2xl">
          <h3 className="text-slate-400 text-xl not-italic font-normal leading-[140%] text-center pb-2">
            Profilga kirish
          </h3>
          <form className="w-full" onSubmit={handleSendCode}>
            <div className="relative flex items-center">
              <select
                value={selectedCountry?.code || ''}
                onChange={handleChange}
                className="border border-sky-50 text-center rounded-lg ps-3 pe-3 py-2 m-1 w-full max-w-[150px]"
              >
                {countries.map((country) => (
                  <option
                    key={`${country.code}-${country.name}`}
                    value={country.code}
                  >
                    {country.flag} ({country.code})
                  </option>
                ))}
              </select>
              <InputMask
                mask="999999999999"
                maskChar={null}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="border border-sky-50 outline-sky-50 text-gray-900 text-sm rounded-lg block w-full ps-5 p-2.5"
                placeholder="Phone number"
                alwaysShowMask
                required
              />
              <button
                type="submit"
                className={`bg-blue-500 text-white px-4 py-2 ml-4 rounded w-full max-w-[150px] ${isLoading || isCodeSent || seconds === 0
                  ? 'cursor-not-allowed bg-gray-400'
                  : 'hover:bg-blue-400'
                  }`}
                disabled={isLoading || isCodeSent || seconds === 0}
              >
                {isLoading
                  ? 'Sending...'
                  : isCodeSent
                    ? 'Code Sent'
                    : 'Send Code'}
              </button>
            </div>
          </form>
          <form onSubmit={handleCodeSubmit}>
            <div className="mt-4">
              <input
                type="text"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                maxLength="6"
                className="border border-gray-300 rounded p-2 w-full mb-4"
                placeholder="Confirm code"
                required
              />
              <div className="flex items-center justify-between mb-4">
                {isCodeSent && seconds > 0 ? (
                  <div className="text-gray-500">{seconds} s</div>
                ) : (
                  seconds === 0 && (
                    <button
                      onClick={handleSendCode}
                      className="text-blue-500 hover:underline focus:outline-none"
                    >
                      Resend Code
                    </button>
                  )
                )}
              </div>
              <button
                type="submit"
                className={`w-full text-white px-4 py-2 rounded ${confirmationCode.length < 6
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-400'
                  }`}
                disabled={confirmationCode.length < 6}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="absolute bg-[rgba(245,_245,_249,_0.90)] w-full h-full top-0"></div>
    </div>
  );
};

export default SignIn;
