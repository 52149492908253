import React from 'react';
import Users_component from './Users_component';

function Users_section() {
  return (
    <div className="lg:ml-64">
      <div className="rounded-lg  dark:border-gray-700 lg:mt-16 mt-0">
        <Users_component />
      </div>
    </div>
  );
}

export default Users_section;
