import React, { useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import {
  useBlockUserMutation,
  useGetUsersQuery,
  useUnblockUserMutation,
  useChangeRoleMutation,
} from "../../../services";
import Pagination from "./components/Pagination";
import TableRow from "./components/TableRow";
import SearchBar from "./components/SearchBar";
import AddUserModal from "./components/AddUserModal";
import AddModal from "./ AddModal";

const Users_component = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    userId: null,
    newRole: "",
  });

  const { data, isLoading, error } = useGetUsersQuery({
    page: currentPage,
    limit,
  });
  const users = data?.data || [];
  const pagination = data?.pagination || {};
  const totalPages = pagination.totalPages || 0;
  const totalDocuments = pagination.totalDocuments || 0;

  const [blockUser] = useBlockUserMutation();
  const [unblockUser] = useUnblockUserMutation();
  const [changeRole] = useChangeRoleMutation();

  // Handle block/unblock user
  const handleToggleBlockUser = async (userId, isBlocked) => {
    try {
      if (isBlocked) {
        await unblockUser(userId);
        toast.success("User unblocked successfully!");
      } else {
        await blockUser(userId);
        toast.success("User blocked successfully!");
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  // Handle change role
  const handleChangeRole = (userId, newRole) => {
    setConfirmationModal({ isOpen: true, userId, newRole });
  };

  const confirmChangeRole = async () => {
    try {
      const { userId, newRole } = confirmationModal;
      await changeRole({ userId, role: newRole }).unwrap();
      toast.success(`Role changed to ${newRole} successfully!`);
      setConfirmationModal({ isOpen: false, userId: null, newRole: "" });
    } catch (error) {
      toast.error(`Failed to change role: ${error.message}`);
      setConfirmationModal({ isOpen: false, userId: null, newRole: "" });
    }
  };

  const cancelChangeRole = () => {
    setConfirmationModal({ isOpen: false, userId: null, newRole: "" });
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const filteredUsers = useCallback(
    () =>
      users?.filter((user) =>
        user.fullName.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [users, searchQuery]
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading users: {error.message}</div>;

  return (
    <section className="p-3 sm:p-5">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-bold text-gray-800">Users</h3>
        <div className="flex space-x-3">
          <AddUserModal />
          <AddModal />
        </div>
      </div>

      {/* Search Bar */}
      <SearchBar value={searchQuery} onChange={handleSearchChange} />

      {/* User Table */}
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full text-sm text-left text-gray-500">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Gender</th>
              <th className="px-4 py-2">Phone</th>
              <th className="px-4 py-2">Role</th>
              <th className="px-4 py-2 text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers().map((user) => (
              <TableRow
                key={user._id}
                user={user}
                onToggleBlockUser={handleToggleBlockUser}
                onChangeRole={handleChangeRole}
              />
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalDocuments={totalDocuments}
        limit={limit}
        onPageChange={handlePageChange}
      />

      {/* Confirmation Modal */}
      {confirmationModal.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">Confirm Role Change</h2>
            <p>
              Are you sure you want to change the role to{" "}
              <strong>{confirmationModal.newRole}</strong>?
            </p>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={cancelChangeRole}
                className="px-4 py-2 bg-gray-500 text-white rounded"
              >
                Cancel
              </button>
              <button
                onClick={confirmChangeRole}
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Users_component;
