import React from 'react';

function SearchBar({ value, onChange }) {
  return (
    <div className="mb-4">
      <input
        type="text"
        placeholder="Search users..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full p-2 border rounded-lg"
      />
    </div>
  );
}

export default SearchBar;
