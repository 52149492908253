import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCompany,
  approveCompany,
} from "../../../store/companiesApi/companiesThunks";
import { setCurrentPage } from "../../../store/companiesApi/companiesSlice";
import CompaniesCard from "./CompaniesCard";

function Companies() {
  const dispatch = useDispatch();
  const { companies, status, error, pagination } = useSelector(
    (state) => state.companies
  );

  useEffect(() => {
    dispatch(
      getAllCompany({ page: pagination.currentPage, limit: pagination.limit })
    );
  }, [dispatch, pagination.currentPage, pagination.limit]);

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleDelete = (companyId) => {
    // Implement the delete functionality here
    console.log(`Delete company with ID: ${companyId}`);
  };

  const handleChangeStatus = (companyId, status) => {
    dispatch(approveCompany({ companyId, status })).then(() => {
      dispatch(
        getAllCompany({ page: pagination.currentPage, limit: pagination.limit })
      );
    });
  };

  return (
    <div className="p-3 sm:p-5">
      <div className="lg:ml-64">
        <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-0">
          <h3 className="text-gray-900 dark:text-gray-800 mb-3 text-2xl font-bold">
            Companies
          </h3>
        </div>
        {status === "loading" && <p>Loading companies...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
        {status === "succeeded" && (
          <div>
            <div className="grid mt-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
              {companies?.map((company) => (
                <CompaniesCard
                  key={company._id}
                  company={company}
                  handleDelete={handleDelete}
                  handleChangeStatus={handleChangeStatus}
                />
              ))}
            </div>
            <div className="flex justify-center mt-4">
              {Array.from({ length: pagination.totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  disabled={pagination.currentPage === index + 1}
                  className={`px-4 py-2 mx-1 border rounded ${
                    pagination.currentPage === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-white text-blue-500 border-blue-500"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Companies;
